import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmployerStatusUpdateDto } from 'src/app/model/employerStatusUpdateDto';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient ) { 
    
  }

  // get user list for admin
  getUserList(searchText: string, sortColumn: string, sortOrder: string, pageNumber: number, pageSize: number, roleId: string): Observable<any> {

    var result = this.http.get<any>(`${this.apiUrl}/admin/userlist`, { params: new HttpParams()
        .set("searchText", searchText.toString())
        .set("sortColumn", sortColumn)
        .set("sortDirection", sortOrder)
        .set("pageIndex", pageNumber.toString())
        .set("pageSize", pageSize.toString())
        .set("roleId", roleId.toString())
      });

    return result;
  }

  // update employer status
  updateEmployerStatus(data: EmployerStatusUpdateDto): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/admin/employer/statusupdate`, data);
  }
}
